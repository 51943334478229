import { Suspense, lazy, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Navigate, Route, Routes } from "react-router-dom";
import DefaultLayout from "./Presentation/layout/DefaultLayout";
import routes from "./routes";
import React from "react";
import { Provider } from "react-redux";
import store from "./reducers/store";
import LogintLayout from "./Presentation/layout/LoginLayout";
import Login from "./Presentation/Pages/Auth/Login";
import ConfirmCode from "./Presentation/Pages/Auth/ConfirmCode";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "./custom.css";
import storageService from "./Services/storage.service";
import getUserRole from "./Auth/getUserRole";
import NotFound404 from "./Presentation/Pages/404/404";

function App() {
  useEffect(() => {
    //Open submenu on hover in compact sidebar mode and horizontal menu mode
    $(document).on(
      "mouseenter mouseleave",
      ".sidebar .nav-item",
      function (ev) {
        var body = $("body");
        var sidebarIconOnly = body.hasClass("sidebar-icon-only");
        var sidebarFixed = body.hasClass("sidebar-fixed");
        if (!("ontouchstart" in document.documentElement)) {
          if (sidebarIconOnly) {
            if (sidebarFixed) {
              if (ev.type === "mouseenter") {
                body.removeClass("sidebar-icon-only");
              }
            } else {
              var $menuItem = $(this);
              if (ev.type === "mouseenter") {
                $menuItem.addClass("hover-open");
              } else {
                $menuItem.removeClass("hover-open");
              }
            }
          }
        }
      }
    );
  }, []);

  const ProtectedRoute = ({
    element: Element,
  }: {
    element: React.ComponentType;
  }) => {
    const token = storageService?.getItem("user")?.token; // Use your authentication context or hook to get the token
    if (!token) {
      // Redirect to the root path if token is null
      return <Navigate to="/" />;
    }

    return <Element />;
  };

  const userRole = getUserRole();
  const canAccessRoute = (role:any, userRole:any) => {
    if (role) {
      return role.includes(userRole);
    }
    return true; // Si la route n'a pas de rôle spécifié, tout le monde peut y accéder
  };
  

  return (
    <Provider store={store}>
      <Helmet>
        <link rel="stylesheet" href="/assets/css/style.css" type="text/css" />
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js"></script>
        <title>Fandresena</title>
      </Helmet>

      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route element={<LogintLayout />}>
          <Route path="/auth/confirm" element={<ConfirmCode />}></Route>
        </Route>
        <Route path="/unauthorized" element={<NotFound404 />}></Route>

        <Route element={<DefaultLayout />}>
          {routes.map(({ path, component: Component, role }) => (
            <Route
              path={path}
              element={
                canAccessRoute(role, userRole) ? (
                  <Suspense>
                    <ProtectedRoute element={Component} />
                  </Suspense>
                ) : (
                  <Navigate to="/unauthorized" />)
              }
            />
          ))}
        </Route>
        <Route path="*" element={<NotFound404 />} />
      </Routes>
      <ToastContainer />
    </Provider>
  );
}

export default App;
