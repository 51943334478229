import { Member } from "../Model/Member";
import { Http } from "../../Services/Http";
import { MemberRepository } from "../Repository/MemberRepository";

export class MemberSourceImpl implements MemberRepository {
    //fetch all
    async getMember(param: any): Promise<Member[]> {
        let data;
        try {
            data = "/liste-membre/" + param;
            
            const res = await Http?.get<Member[]>(data);
            
            if (res?.status === 400) {
                return [];
            }

            return res?.status === 200 ? res.data : [];
        } catch (error) {
            console.error(error);
            return [];
        }
    }
    
    //search member
    async searchMember(param: any): Promise<any> {
        try {
            const res = await Http?.get<Member[]>("/liste-membre?page=1&items=100&text=" + param);
            
            if (res?.status === 400) {
                return [];
            }

            return res?.status === 200 ? res.data : [];
        } catch (error) {
            console.error(error);
            return [];
        }
    }
    
    //print member
    async printMember(param: any, type:string): Promise<any> {
        try {
            let urlExport = "";
            if (type === "pdf") {
                urlExport = "/liste-membre/export?fileType=pdf";
            } else {
                urlExport =  "/liste-membre/export/liste-carte"
            }
            
            const res = await Http?.post<any>(urlExport, param);
            return res?.status == 200 ? res.data : null;
        } catch (error) {
            console.error(error);
        }
    }
    
    //get member by Id
    async getMemberById(param: any): Promise<any> {
        try {
            const res = await Http?.get<Member>("/membre/" + param);
            return res?.status == 200 ? res.data : null;
        } catch (error) {
            console.error(error);
        }
    }
    
    //check member by Id
    async deliverMember(param: any): Promise<any> {
        try {
            const res = await Http?.get<Member>("/membre/check/" + param);
            return res?.status == 200 ? res.data : null;
        } catch (error) {
            console.error(error);
        }
    }
}
