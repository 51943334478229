import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { BASE_URL } from "../Constant/Url";

const instance = axios.create({
  baseURL: BASE_URL,
});

type HTTPRequestConfig = AxiosRequestConfig;

const api = (axios: AxiosInstance) => {
    const data: any = localStorage?.getItem('user');
    const token = JSON.parse(data)?.token;
    return {
        get: <T>(url: string, config: HTTPRequestConfig = {}) => {
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${token}`,
            };
            return axios.get<T>(url, config);
        },
        
        delete: <T>(url: string, config: HTTPRequestConfig = {}) => {
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${token}`,
            };
            return axios.delete<T>(url, config);
        },
        
        put: <T>(url: string, body: unknown, config: HTTPRequestConfig = {}) => {
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${token}`,
            };
            return axios.put<T>(url, body, config);
        },
        
        patch: <T>(url: string, body: unknown, config: HTTPRequestConfig = {}) => {
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${token}`,
            };
            return axios.patch<T>(url, body, config);
        },
        
        post: <T>(url: string, body: unknown, config: HTTPRequestConfig = {}) => {
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${token}`,
            };
            return axios.post<T>(url, body, config);
        },
    };
};

export const Http = api(instance);