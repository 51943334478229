import { PROVINCE_ITEM, DELETE_ITEM } from "../Constant/actionTypes";
import ListeService from "../Services/list.service";

const initialState = {
  provinces: [],
};

const ListReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROVINCE_ITEM:
      ListeService.getProvinces().then(responses => {
        return {
          ...state,
          provinces: responses.data,
        };
      })
      return {
        ...state,
        provinces: state.provinces,
      };

    case DELETE_ITEM:
      return {
        ...state,
        numOfItems: state.numOfItems - 1,
      };
    default:
      return state;
  }
};

export default ListReducer;