import { ParrainRepository } from "../../Repository/ParrainReponsitory";

export class GetParrain {
  private parrainRepo: ParrainRepository;
  constructor(_parrainRepo: ParrainRepository) {
    this.parrainRepo = _parrainRepo;
  }

  async fetchParrain(param: any) {
    const data = await this.parrainRepo.searchParrain(param);
    return data;
  }
}