const partisans = [
    {
        section: {
            title: 'Etat civil <span class="required-label">(obligatoire)<span>',
            form: [
                {
                    colSize: 6,
                    radio: false,
                    input: [
                        {
                            colSize: 6,
                            label: 'Nom',
                            type: 'text',
                            class: 'nom col-md-6',
                            placeholder: 'Nom',
                            name: 'nom',
                            id:'nom',
                            required: true
                        },
                        {
                            colSize: 6,
                            label: 'Prenom (s)',
                            type: 'text',
                            class: 'prenom',
                            placeholder: 'Prenom (s)',
                            name: 'prenom',
                            id: 'prenom'
                        },
                        {
                            colSize: 6,
                            label: 'Date de naissance',
                            type: 'text',
                            class: 'datenaiss date',
                            placeholder: 'DD/MM/AAAA',
                            name: 'datenaiss',
                            pattern: '/^\d{4}-\d{2}-\d{2}$/',
                            message: 'Date doit etre sous forme DD/MM/AAAA',
                            id:'datenaiss'
                        },
                        {
                            colSize: 6,
                            label: 'Lieu de naissance',
                            type: 'text',
                            class: 'col-md-6',
                            placeholder: 'Lieu de naissance',
                            name: 'lieu_naiss'
                        }
                    ]
                },
                {
                    colSize: 6,
                    radio: true,
                    input: [
                        {
                            colSize: 3,
                            label: 'Civilité',
                            type: 'radio',
                            class: 'sexe p-t',
                            placeholder: 'Homme',
                            value: 'M',
                            name: 'sexe'
                        },
                        {
                            colSize: 3,
                            label: '',
                            type: 'radio',
                            class: 'sexe p-t',
                            placeholder: 'Femme',
                            value: 'F',
                            name: 'sexe'
                        },
                        {
                            colSize: 3,
                            label: 'Statut matrimonial',
                            type: 'radio',
                            class: 'celibataire p-t',
                            placeholder: 'Marié(e)',
                            name: 'celibataire',
                            value: false
                        },
                        {
                            colSize: 3,
                            label: '',
                            type: 'radio',
                            class: 'celibataire p-t',
                            placeholder: 'Célibataire',
                            name: 'celibataire',
                            value: true
                        },
                        {
                            colSize: 6,
                            label: 'N° CIN',
                            type: 'text',
                            class: 'cin',
                            placeholder: 'N° CIN',
                            name: 'cin',
                            pattern: '/^\d{12}$/',
                            message:'',
                            id:'cin',
                            required: true
                        },
                        {
                            colSize: 6,
                            label: 'Fait le',
                            type: 'text',
                            class: 'date_cin date',
                            placeholder: 'DD/MM/AAAA',
                            name: 'date_cin',
                            pattern: '/^\d{4}-\d{2}-\d{2}$/',
                            message: 'Date doit etre sous forme DD/MM/AAAA',
                            id: 'date_cin'
                        }
                    ]
                },
                {
                    colSize: 12,
                    radio: false, 
                    input: [
                        {
                            colSize: 6,
                            label: 'Nom du père',
                            type: 'text',
                            class: 'nomPere',
                            placeholder: 'Nom du père',
                            name: 'nomPere',
                            id:'nomPere'
                        },
                        {
                            colSize: 6,
                            label: 'Nom de la mère',
                            type: 'text',
                            class: 'nomMere',
                            placeholder: 'Nom de la mère',
                            name: 'nomMere',
                            id: 'nomMere'
                        },
                    ]
                }

            ]
        }
    },
    {
        section: {
            title: 'Adresse et coordonnées <span class="required-label">(obligatoire)<span>',
            form: [
                {
                    colSize: 12,
                    radio: false,
                    input: [
                        {
                            colSize: 4,
                            label: 'Adresse',
                            type: 'text',
                            class: 'adresse col-md-4',
                            placeholder: 'Adresse',
                            name: 'adresse',
                            id: 'adresse',
                            required: true
                        },
                        {
                            colSize: 4,
                            label: 'Province',
                            type: 'select',
                            class: 'col-md-4',
                            placeholder: 'Province',
                            name: 'province',
                            require: true,
                            options: [
                                {
                                    value: '1',
                                    label: 'Value 1'
                                },
                                {
                                    value: '2',
                                    label: 'Value 2'
                                }
                            ]
                        },
                        {
                            colSize: 4,
                            label: 'Région',
                            type: 'select',
                            class: 'col-md-4',
                            placeholder: 'Région',
                            name: 'region',
                            required: true,
                        },
                        {
                            colSize: 3,
                            label: 'District',
                            type: 'select',
                            class: 'col-md-3',
                            placeholder: 'District',
                            name: 'district',
                            required: true,
                        
                        },
                        {
                            colSize: 3,
                            label: 'Commune',
                            type: 'select',
                            class: 'col-md-3',
                            placeholder: 'Commune',
                            name: 'commune',
                            required: true,
                            options: [
                                {
                                    value: '1',
                                    label: 'Value 1'
                                },
                                {
                                    value: '2',
                                    label: 'Value 2'
                                }
                            ]
                        },
                        {
                            colSize: 3,
                            label: 'Fokontany',
                            type: 'select',
                            class: 'id_fokontany col-md-3',
                            placeholder: 'Fokontany',
                            name: 'id_fokontany',
                            id: 'id_fokontany',
                            required: true,
                            options: [
                                {
                                    value: '1',
                                    label: 'Value 1'
                                },
                                {
                                    value: '2',
                                    label: 'Value 2'
                                }
                            ]
                        },
                        /*{
                            colSize: 3,
                            label: 'Arrondissement',
                            type: 'text',
                            class: 'col-md-3',
                            placeholder: 'Arrondissement',
                            name: 'arrondissement'
                        },*/
                        {
                            colSize: 4,
                            label: 'Téléphone 1',
                            type: 'text',
                            class: 'telephone1 col-md-3',
                            placeholder: 'Téléphone 1',
                            name: 'telephone1',
                            id: 'telephone1',
                            //required: true,
                        },
                        {
                            colSize: 4,
                            label: 'Téléphone 2',
                            type: 'text',
                            class: 'col-md-3',
                            placeholder: 'Téléphone 2',
                            name: 'telephone2',
                        },
                        {
                            colSize: 4,
                            label: 'Téléphone 3',
                            type: 'text',
                            class: 'col-md-3',
                            placeholder: 'Téléphone 3',
                            name: 'telephone3'
                        },
                        {
                            colSize: 6,
                            label: 'Profil facebook',
                            type: 'text',
                            class: 'profile_fb col-md-3',
                            placeholder: 'Profil facebook',
                            name: 'profile_fb',
                            id:'profile_fb'
                        },
                        {
                            colSize: 6,
                            label: 'Adresse email',
                            type: 'text',
                            class: 'mail col-md-3',
                            placeholder: 'Adresse email',
                            name: 'mail',
                            id:'mail'
                        },
                    ]
                }
            ]
        }
    },
    {
        section: {
            title: 'Situation professionnelle <span class="required-label">(obligatoire)<span>',
            form: [
                {
                    colSize: 12,
                    radio: false,
                    input: [
                        {
                            colSize: 6,
                            label: 'Profession',
                            type: 'text',
                            class: 'profession',
                            placeholder: 'Profession',
                            name: 'profession',
                            id: 'profession'
                        },
                        {
                            colSize: 6,
                            label: 'Nom de la société',
                            type: 'text',
                            class: 'nom_societe col-md-6',
                            placeholder: 'Nom de la société',
                            name: 'nom_societe',
                            id: 'nom_societe',
                        },
                    ]
                },{
                    colSize: 12,
                    radio: true,
                    input: [
                        {
                            colSize: 2,
                            label: 'Autre statut',
                            type: 'radio',
                            class: 'autre_status p-t',
                            placeholder: 'Indépendant',
                            name: 'autre_status',
                            value:'Indépendant'
                        },
                        {
                            colSize:3,
                            label: '',
                            type: 'radio',
                            class: 'p-t',
                            placeholder: 'En recherche d’emploi',
                            name: 'autre_status',
                            value: 'En recherche d’emploi'
                        },
                        {
                            colSize: 2,
                            label: '',
                            type: 'radio',
                            class: 'p-t',
                            placeholder: 'Retraité',
                            name: 'autre_status',
                            value:'Retraité'
                        },
                        {
                            colSize: 2,
                            label: '',
                            type: 'radio',
                            class: 'p-t',
                            placeholder: 'Régime',
                            name: 'autre_status',
                            value:'Régime'
                        },
                    ]
                },
                {
                    colSize: 12,
                    radio: false,
                    formSup: true,
                    input: [
                        {
                            colSize: 6,
                            label: 'Etudes',
                            type: 'text',
                            class: 'etude col-md-6',
                            placeholder: 'Etudes',
                            name: 'etude'
                        },
                        {
                            colSize: 6,
                            label: 'Diplôme',
                            type: 'text',
                            class: 'diplome col-md-6',
                            placeholder: 'Diplôme',
                            name: 'diplome'
                        },
                    ]
                },
                {
                    colSize: 12,
                    radio: false,
                    formSup: true,
                    input: [
                        {
                            formAdd: true,
                            colSize: 12,
                            label: 'Ajouter une étude',
                            type: 'formadd',
                            class: 'p-t add-form',
                            placeholder: '',
                            name: ''
                        },
                        {

                            colSize: 12,
                            label: 'Talent(s)',
                            type: 'text',
                            class: 'talent p-t add-form',
                            placeholder: 'Talent(s)',
                            name: 'talent',
                            id:'talent'
                        },
                    ]
                }
            ]
        }
    },
    {
        section: {
            title: 'Parcours politique',
            form: [
                {
                    colSize: 12,
                    radio: false,
                    input: [
                        {
                            colSize: 6,
                            label: 'Ancien parti politique',
                            type: 'text',
                            class: 'col-md-6',
                            placeholder: 'Ancien parti politique',
                            name: 'ancien_partie_politique'
                        },
                        {
                            colSize: 6,
                            label: 'Rôle dans le parti',
                            type: 'text',
                            class: 'col-md-6',
                            placeholder: 'Rôle dans le parti',
                            name: 'role_dans_la_parti'
                        },
                        {
                            colSize: 2,
                            label: 'la personne est-elle inscrite dans la liste électorale ?',
                            type: 'radio',
                            class: 'p-t',
                            placeholder: 'OUI',
                            name: 'is_in_list',
                            value: true,
                        },
                        {
                            colSize: 2,
                            label: '',
                            type: 'radio',
                            class: 'p-t',
                            placeholder: 'NON',
                            name: 'is_in_list',
                            value:false
                        },
                        {
                            colSize: 7,
                            label: 'N° carte élécteur',
                            type: 'text',
                            class: 'col-md-3',
                            placeholder: 'N° carte élécteur',
                            name: 'num_electeur'
                        },
                        {
                            colSize: 2,
                            label: 'A été déjà candidat ?',
                            type: 'radio',
                            class: 'p-t',
                            placeholder: 'OUI',
                            name: 'is_candidat',
                            value: true,
                        },
                        {
                            colSize: 2,
                            label: '',
                            type: 'radio',
                            class: 'p-t',
                            placeholder: 'NON',
                            name: 'is_candidat',
                            value: false,
                        },
                        /*{
                            colSize: 3,
                            label: 'N° candidat',
                            type: 'text',
                            class: 'col-md-3',
                            placeholder: 'N° candidat',
                            name: 'num_candidat_pres'
                        },
                        {
                            colSize: 3,
                            label: 'Parti ou association ',
                            type: 'text',
                            class: 'col-md-3',
                            placeholder: 'Parti ou association ',
                            name: 'parti_presidence '
                        },
                        {
                            colSize: 2,
                            label: 'Nombre de voix reçus en % ',
                            type: 'text',
                            class: 'col-md-2',
                            placeholder: 'Placeholder',
                            name: 'voix_presidence'
                        },
                        //
                        {
                            colSize: 2,
                            label: 'A été déjà candidat Député ?',
                            type: 'radio',
                            class: 'p-t',
                            placeholder: 'OUI',
                            name: 'is_candidat_depute',
                            value: 1,
                        },
                        {
                            colSize: 2,
                            label: '',
                            type: 'radio',
                            class: 'p-t',
                            placeholder: 'NON',
                            name: 'is_candidat_depute',
                            value: 0
                        },
                        {
                            colSize: 3,
                            label: 'N° candidat',
                            type: 'text',
                            class: 'col-md-3',
                            placeholder: 'N° candidat',
                            name: 'num_candidat_depute'
                        },
                        {
                            colSize: 3,
                            label: 'Parti ou association ',
                            type: 'text',
                            class: 'col-md-3',
                            placeholder: 'Parti ou association',
                            name: 'parti_depute'
                        },
                        {
                            colSize: 2,
                            label: 'Période du Mandat  ',
                            type: 'text',
                            class: 'col-md-2',
                            placeholder: 'Période du Mandat',
                            name: 'periode_mandat '
                        },
                        //
                        {
                            colSize: 2,
                            label: 'A été déjà candidat à la Mairie ?',
                            type: 'radio',
                            class: 'p-t',
                            placeholder: 'OUI',
                            name: 'is_candidat_maire',
                            value: 1,
                        },
                        {
                            colSize: 2,
                            label: '',
                            type: 'radio',
                            class: 'p-t',
                            placeholder: 'NON',
                            name: 'is_candidat_maire',
                            value: 0,
                        },
                        {
                            colSize:2 ,
                            label: 'N° candidat',
                            type: 'text',
                            class: 'col-md-3',
                            placeholder: 'N° candidat',
                            name: 'num_candidat_maire'
                        },
                        {
                            colSize: 2,
                            label: 'Période du Mandat ',
                            type: 'text',
                            class: 'col-md-3',
                            placeholder: 'Période du Mandat',
                            name: 'periode_mandat_maire'
                        },
                        {
                            colSize: 2,
                            label: 'Parti ou association ',
                            type: 'text',
                            class: 'col-md-3',
                            placeholder: 'Parti ou association',
                            name: 'parti_maire'
                        },
                        {
                            colSize: 2,
                            label: 'Nombre de voix reçus en % ',
                            type: 'text',
                            class: 'col-md-2',
                            placeholder: 'Nombre de voix reçus en %',
                            name: 'voix_maire'
                        },*/,

                    ]
                },
                {
                    colSize:12,
                    candidat: true,
                    class:'form-none',
                    input: [
                        {
                            colSize: 2,
                            label: 'Candidat',
                            type: 'select',
                            class: 'p-t',
                            placeholder: 'Candidat',
                            name: 'is_candidat_president',
                            value:0,
                        },
                        {
                            colSize:2 ,
                            label: 'N° candidat',
                            type: 'text',
                            class: 'col-md-3',
                            placeholder: 'N° candidat',
                            name: 'num_candidat_maire'
                        },
                        {
                            colSize: 3,
                            label: 'Période du Mandat ',
                            type: 'text',
                            class: 'col-md-3',
                            placeholder: 'Période du Mandat',
                            name: 'periode_mandat_maire'
                        },
                        {
                            colSize: 3,
                            label: 'Parti ou association ',
                            type: 'text',
                            class: 'col-md-3',
                            placeholder: 'Parti ou association',
                            name: 'parti_maire'
                        },
                        {
                            colSize: 2,
                            label: 'Nombre de voix reçus en % ',
                            type: 'text',
                            class: 'col-md-2',
                            placeholder: 'Nombre de voix reçus en %',
                            name: 'voix_maire'
                        }
                    ]
                }
            ]
        }
    },
    {
        section: {
            title: 'Motivation',
            form: [
                {
                    colSize: 12,
                    radio: false,
                    input: [
                        {
                            colSize: 12,
                            label: 'Motif d’intégration du parti TGV',
                            type: 'textarea',
                            class: 'col-md-12',
                            placeholder: 'Motif d’intégration du parti TGV',
                            name: 'motif',
                            row: 2,
                        },
                        {
                            colSize: 12,
                            label: 'Attente vis à vis du parti TGV',
                            type: 'textarea',
                            class: 'col-md-12',
                            placeholder: 'Attente vis à vis du parti TGV',
                            name: 'attente',
                            row:3,
                        },
                        {
                            colSize: 12,
                            label: 'Contribution au parti TGV',
                            type: 'textarea',
                            class: 'col-md-12',
                            placeholder: 'Contribution au parti TGV',
                            name: 'apport',
                            row:3,
                        }
                    ]
                }
            ]
        }
    },
    {
        section: {
            title: 'Fichiers rattachés <span class="required-label">(obligatoire)<span>',
            form: [
                {
                    colSize: 6,
                    radio: false,
                    input: [
                        {
                            colSize: 12,
                            label: 'CIN',
                            type: 'file',
                            class: 'photo_cin',
                            placeholder: 'CIN',
                            name: 'photo_cin'
                        },
                        {
                            colSize: 12,
                            label: 'Photo d’identité',
                            type: 'file',
                            class: 'photos',
                            placeholder: 'Photo d’identité',
                            name: 'photos',
                            //required: true
                        },
                        {
                            colSize: 12,
                            label: 'Fiche d\'adhésion',
                            type: 'file',
                            class: 'photo_residence',
                            placeholder: 'Certificat de résidence',
                            name: 'photo_residence',
                            required: true,
                        },
                   
                    ]
                }
            ]
        }
    },
    {
        section: {
            title: 'Adhésion',
            form: [
                {
                    colSize: 12,
                    radio: false,
                    input: [
                        {
                            colSize: 4,
                            label: 'Date d’adhésion au parti',
                            type: 'text',
                            class: 'col-md-3 date',
                            placeholder: 'DD/MM/AAAA',
                            name: 'date_adhesion',
                            pattern: '/^\d{4}-\d{2}-\d{2}$/',
                            message: 'Date doit etre sous forme DD/MM/AAAA',
                        },
                        {
                            colSize: 4,
                            label: 'Numéro d\'adhésion',
                            type: 'text',
                            class: 'num_adhesion col-md-3',
                            placeholder: 'Numéro',
                            name: 'num_adhesion',
                            required: true,
                            id: 'num_adhesion'

                        },
                       /* {
                            colSize: 4,
                            label: 'District',
                            type: 'text',
                            class: 'col-md-3',
                            placeholder: 'District',
                            name: 'districts',
                        },//*/
                        {
                            colSize: 3,
                            label: 'ID du parrain',
                            type: 'text',
                            class: 'col-md-3',
                            placeholder: 'ID du parrain',
                            name: 'id_parrain',
                        },
                        {
                            colSize: 3,
                            label: 'Nom du parrain',
                            type: 'text',
                            class: 'nom_parrain',
                            placeholder: 'Nom du parrain',
                            name: 'nom_parrain',
                        },
                        {
                            colSize: 3,
                            label: 'Prénom du parrain',
                            type: 'text',
                            class: 'col-md-3',
                            placeholder: 'Prénom du parrain',
                            name: 'prenom_parrain',
                        },
                        {
                            colSize: 3,
                            label: 'Rôle du parrain au sein du parti',
                            type: 'text',
                            class: 'col-md-3',
                            placeholder: 'Rôle du parrain au sein du parti',
                            name: 'role_parrain',
                        }
                    ]
                }
            ]
        }
    },
    {
        section: {
            title: '',
            form: [
               {
                colSize: 12,
                radio: false,
                input: [
                    {
                        colSize: 4,
                        label: 'Type',
                        type: 'select',
                        class: '',
                        placeholder: 'Type',
                        name: 'type'
                    },
                ]
               } 
            ]
        }
    }
]

export default partisans;