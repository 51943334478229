class StorageService {
  constructor() {}

  // Méthode pour définir un élément dans localStorage
  setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  // Méthode pour récupérer un élément de localStorage
  getItem(key) {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  // Méthode pour supprimer un élément de localStorage
  removeItem(key) {
    localStorage.removeItem(key);
  }

  // Méthode pour mettre à jour un élément dans localStorage
  updateItem(key, value) {
    // Vérifie si l'élément existe, puis le met à jour
    if (localStorage.getItem(key)) {
      localStorage.setItem(key, JSON.stringify(value));
      return true; // Renvoie vrai si la mise à jour réussit
    }
    return false; // Renvoie faux si l'élément n'existe pas
  }

  checkDashboard(isLogin = false) {
    const user = this.getItem("user");
    if (user) {
      window.location.href = "/dashboard";
    }
  }

  checkLogin() {
    const user = this.getItem("user");
    if (!user) {
      window.location.href = "/";
    }
  }

  getCurrentDate() {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    const dateFormatter = new Intl.DateTimeFormat("fr-FR", options);
    const formattedDate = dateFormatter.format(new Date());
    return formattedDate;
  }
  formatDate(date: string): string {
    return date.split("-").reverse().join("/");
  }

  getFormattedDate(date, format = "yyyy-mm-dd") {
    const formattedDate = date
      ? (() => {
          const dateObj = new Date(date);
          const year = dateObj.getFullYear();
          const month = String(dateObj.getMonth() + 1).padStart(2, "0");
          const day = String(dateObj.getDate()).padStart(2, "0");

          switch (format) {
            case "dd-mm-yyyy":
              return `${day}-${month}-${year}`;
            case "mm-dd-yyyy":
              return `${month}-${day}-${year}`;
            default:
              return `${year}-${month}-${day}`;
          }
        })()
      : "";

    return formattedDate;
  }
}

// Exports de la classe StorageService
export default new StorageService();
