import { Modal } from "react-bootstrap";
import Webcam from 'react-webcam';
import { Button } from "react-bootstrap";
const CameraModal = (props) => {
    return (
        <Modal show={props.cameraActive} >
        <Modal.Header closeButton>
          <Modal.Title>Prendre une photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div>
            <Webcam
              audio={false}
              ref={props.webcamRef}
              screenshotFormat="image/jpeg"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-sm btn-cancel" variant="secondary" onClick={props.handleClose}>
            Fermer
          </Button>
          <Button className="btn btn-sm btn-cancel" variant="secondary" onClick={props.stopCamera}>
            Enregirer
          </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default CameraModal;