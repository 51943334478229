import axios from "axios";
import authHeader from "./auth.header";
import { BASE_URL } from "../Constant/Url";

class ListeService {
  getProvinces() {
    return axios.get(BASE_URL + "/location/province", authHeader());
  }

  getRegions(idProvince) {
    return axios.get(BASE_URL + "/location/region/" + idProvince, authHeader());
  }

  getDistricts(idRegion) {
    return axios.get(BASE_URL + "/location/district/" + idRegion, authHeader());
  }

  getCommunes(idDistrict) {
    return axios.get(
      BASE_URL + "/location/commune/" + idDistrict,
      authHeader()
    );
  }

  getFokontany(idCommune) {
    return axios.get(
      BASE_URL + "/location/fokontany/" + idCommune,
      authHeader()
    );
  }

  createMember(data) {
    return axios.post(BASE_URL + "/membre/create", data, authHeader());
  }

  updateMembre(data, id) {
    return axios.post(BASE_URL + "/membre/update/" + id, data, authHeader());
  }
  async searchElector(data) {
    try {
      const response = await axios.post(
        BASE_URL + "/electeur/searchElecteur",
        data,
        authHeader()
      );
      return response.data;
    } catch (error) {
      console.log("Error on ws", error.response.data.errorMessage);

      throw error.response.data.errorMessage;
      // throw error; // Propager l'erreur pour la gestion ultérieure si nécessaire
    }
  }
  async getEventType() {
    try {
      const response = await axios.get(
        BASE_URL + "/evenement/type",
        authHeader()
      );
      if (response.data) {
        return response.data.data;
      } else {
        return "error";
      }
    } catch (error) {
      throw error.response.data.errorMessage;
      // throw error; // Propager l'erreur pour la gestion ultérieure si nécessaire
    }
  }

  async createEvent(data) {
    try {
      const response = await axios.post(
        BASE_URL + "/evenement/create",
        data,
        authHeader()
      );
      return response;
    } catch (error) {
      throw error.response.data.errorMessage;
      // throw error; // Propager l'erreur pour la gestion ultérieure si nécessaire
    }
  }

  async createRecompense(data) {
    try {
      const response = await axios.post(
        BASE_URL + "/recompense/create",
        data,
        authHeader()
      );
      return response;
    } catch (error) {
      throw error.response.data.errorMessage;
      // throw error; // Propager l'erreur pour la gestion ultérieure si nécessaire
    }
  }

  async editRecompense(data) {
    try {
      const response = await axios.put(
        BASE_URL + "/recompense/" + data.id_recompense,
        data,
        authHeader()
      );
      return response;
    } catch (error) {
      throw error.response.data.errorMessage;
      // throw error; // Propager l'erreur pour la gestion ultérieure si nécessaire
    }
  }

  async deleteRecompense(data) {
    try {
      const response = await axios.delete(
        BASE_URL + "/recompense/" + data,
        authHeader()
      );
      return response;
    } catch (error) {
      throw error.response.data.errorMessage;
      // throw error; // Propager l'erreur pour la gestion ultérieure si nécessaire
    }
  }
}

export default new ListeService();
