import Form from 'react-bootstrap/Form';
const ConfirmCode = () => {
    return (
        <>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="label-login ">Entrer le code envoyé par SMS</Form.Label>
                <Form.Control className="input-login" type="password" placeholder="****" />
            </Form.Group>

        </>
    )
}
export default ConfirmCode;