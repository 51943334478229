import axios from "axios";
import { BASE_URL } from "../Constant/Url";
import { Http } from "./Http";

class AuthService {
  async loginWs(loginData) {
    return await axios.post(BASE_URL + "/auth/signin", loginData);
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.clear();
    window.location.href = "/";
    // const logoutRes = Http.get("/auth/logout");
    // logoutRes.then((res) => {
    //   if (res?.data?.success === true) {
    //       localStorage.removeItem("user");
    //       localStorage.clear();
    //       window.location.href = "/";
    //     }
    // })
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
}
export default new AuthService();