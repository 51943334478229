import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Notifications {
  static success(message: string = "") {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  static error(message: string = "") {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  static info(message: string = "") {
    toast.info(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  static warning(message: string = "") {
    toast.warn(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export default Notifications;
