import { MemberRepository } from '../../Repository/MemberRepository';

export class GetMemberByID {
  private memberRepo: MemberRepository;
  constructor(_memberRepo: MemberRepository) {
    this.memberRepo = _memberRepo;
  }

  async memberByID(param: any) {
    const data = await this.memberRepo.getMemberById(param);
    return data;
  }
}