import {useState} from 'react';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import { Outlet } from 'react-router-dom';

const DefaultLayout = () => {
 const [mobile,setMobile] = useState(false);

 const handleClickBurger = ()=>{
  setMobile(!mobile)
 }
  return (
    <>
      <div className="container-scroller">
        <Header onClickBurger = {handleClickBurger}/>
        <div className="container-fluid page-body-wrapper">
          <Sidebar active={mobile}/>
          <div className="main-panel">
            <div className="content-wrapper wrap">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DefaultLayout;
