import { Outlet } from 'react-router-dom';
import FondLogin from '../../assets/images/fond-login.png'
import Logo from '../../assets/images/logo_t.png';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import RightIcon from '../../assets/images/Right_Icon.svg';
import '../../login.css';

const LogintLayout = () => {
    const login = () => {
        alert('ok')
    }
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6">
                    <img className="img-login" src={FondLogin} alt="Login" />
                </div>
                <div className="col-md-6 row justify-content-center form-right">

                    <div className="container row">
                        <div className="d-flex justify-content-center">
                            <div>
                                <div className="d-flex justify-content-center">
                                    <div className="form-head">
                                        <img src={Logo} alt="logo" />
                                        <h1 className='text-2xl font-bold dark:text-white text-center'>Authentification</h1>
                                    </div>
                                </div>
                                <div className="">
                                    <Form className="form-login">
                                        <Outlet />
                                        <div className="login-container row justify-content-center">
                                            <Button onClick={login} variant="primary" type="submit" className="btn-login">
                                                Se connecter <img src={RightIcon} alt="Se connecter" />
                                            </Button>
                                        </div>
                                    </Form>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
};

export default LogintLayout;
