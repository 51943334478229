import FondLogin from "../../../assets/images/fond-login.png";
import Logo from "../../../assets/images/logo_t.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import RightIcon from "../../../assets/images/Right_Icon.svg";
import { useState } from "react";
import { loginWs } from "../../../actions/authActions";
import StorageService from "../../../Services/storage.service";

const Login = (props: any) => {
  StorageService.checkDashboard();

  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });

  const onChangeLogin = (param: any, text: any) => {
    setLoginData((prevState) => ({
      ...prevState,
      [param]: text.target.value,
    }));
  };

  const login = async (e: any) => {
    e.preventDefault();
    const credentials = {
      username: loginData.username,
      password: loginData.password,
    };
    loginWs(credentials);
  };

  return (
    <div className="container-fluid">
      <div className="row align-items-center remove-padding">
        <div className="col-md-6 full-screen-container">
          <img className="img-login " src={FondLogin} alt="Login" />
        </div>
        <div className="col-md-6 d-flex align-items-center row justify-content-center">
          <div className="container row">
            <div className="d-flex justify-content-center">
              <div>
                <div className="d-flex justify-content-center">
                  <div className="form-head">
                    <img src={Logo} alt="logo" />
                    <h1 className="text-2xl font-bold dark:text-white text-center">
                      Authentification
                    </h1>
                  </div>
                </div>
                <div className="">
                  <Form className="form-login">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="label-login ">Login</Form.Label>
                      <Form.Control
                        className="input-login"
                        type="text"
                        placeholder="Placeholder"
                        onChange={(text) => onChangeLogin("username", text)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label className="label-login ">
                        Mot de passe
                      </Form.Label>
                      <Form.Control
                        className="input-login"
                        type="password"
                        placeholder="*********"
                        onChange={(text) => onChangeLogin("password", text)}
                      />
                    </Form.Group>
                    <div className="login-container row justify-content-center">
                      <Button
                        onClick={(event) => login(event)}
                        variant="primary"
                        type="submit"
                        className="btn-login"
                      >
                        Se connecter <img src={RightIcon} alt="Se connecter" />
                      </Button>{" "}
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;