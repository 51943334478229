import { useEffect, useState } from "react";
import AuthService from "../../Services/auth.service";
import StorageService from "../../Services/storage.service";

type Burger = {
  onClickBurger : ()=>void
}
const Header = ({onClickBurger}:Burger) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const user = AuthService.getCurrentUser();
  const dateToday = StorageService.getCurrentDate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const formatDate = (date: Date) => {
    return (
      <span>
        {date.getDay()}/{date.getMonth()}/{date.getFullYear()}
      </span>
    );
  };

  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 px-3 fixed-top d-flex flex-row bg-orange">
      <div className="navbar-brand-wrapper d-flex align-items-center bg-orange flex-grow-1 ">
        <div className="d-flex align-items-center flex-grow-1 w-100">
          <a className="d-flex navbar-brand brand-logo flex-grow-1 w-100" href="#">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                clipRule="evenodd"
              />
            </svg>
            <span className="h6 ms-2 mb-0 w-100 overflow-hidden text-ellipsis">{user?.body?.username}</span>
          </a>
          
        </div>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center p-0 gap-2">
        <ul className="navbar-nav navbar-nav-right align-items-center">
          <li className="nav-item d-flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="black"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span className="ms-2 text-black fs-6 fw-bold">
              <span className="fw-bold">{currentTime.getHours().toString().padStart(2, '0')} : </span>
              <span className="fw-bold">{currentTime.getMinutes().toString().padStart(2, '0')}</span>
              <span className="ms-4">{dateToday}</span>
            </span>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center btnSvg p-0"
          type="button"
          data-toggle="offcanvas"
          onClick={onClickBurger}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6 text-black"
          >
            <path
              fillRule="evenodd"
              d="M3 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 5.25zm0 4.5A.75.75 0 013.75 9h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 9.75zm0 4.5a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75zm0 4.5a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </nav>
  );
};

export default Header;