import { Http } from "../../Services/Http";
import { ParrainRepository } from "../Repository/ParrainReponsitory";
import { Parrain } from "../Model/Parrain";

export class ParrainSourceImpl implements ParrainRepository {
    //search member
    async searchParrain(param: any): Promise<any> {
        try {
            const res = await Http?.get<Parrain>("/membre/parrain/" + param);
            return res?.status == 200 ? res.data : null;
        } catch (error) {
            console.error(error);
        }
    }
}