export default function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));

    
    if (user && user.token) {
        const config = {
            headers: {
              'Authorization': `Bearer ${user.token}`,
              'Content-Type': 'application/json',
            },
          };
        return config;  
    } else {
        return {};
    }
}