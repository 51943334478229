import NotFound from "../../../../src/assets/images/404.png";
import AuthService from "../../../Services/auth.service"

const NotFound404 = () => {
    const userConnected = AuthService.getCurrentUser();
    const redirectToUrl = () => {
        const role = userConnected?.body?.role
          switch (role) {
            case 'impression' || 'impression_excel' || 'admin_impression':
              window.location.href = "/member/list";
              break;
            case 'saisie':
              window.location.href = "/add/member";
              break;
            case 'respo_dashboard_district':
              window.location.href = "/dashboarddistrict";
              break
            default:
              window.location.href = "/dashboard";
              break;
          }
    }
  
  return (
        <>
            <div className="flex w-300 h-300">
                <img className="m-auto mt-5" src={NotFound} alt=""/>
            </div>
            <div className="row mt-2">
                <div className="d-flex col-md-12 items-center justify-content-center">
                <a href="#"> 
                    <span className="text-orange text-xl" onClick={()=>redirectToUrl()}>Retour à l'accueil</span>
                </a>
                </div>
            </div>
        </>
  );
};

export default NotFound404;
