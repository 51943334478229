import { useEffect, useRef, useState, useCallback } from "react";
import partisans from "../../../Utils/Partisan";
import Form from 'react-bootstrap/Form';
import ListeService from "../../../Services/list.service";
import { Button } from "react-bootstrap";
import Check from '../../../assets/images/check.png';
import { GetParrain } from "../../../Domain/UseCase/Parrain/GetParrain";
import Notifications from "../Notifications/Notifications";
import $ from "jquery";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import profileService from "../../../Services/profile.service";
import CameraModal from "../../Components/Member/Modal";
import { GetMemberByID } from "../../../Domain/UseCase/Member/GetMemberByID";
import { MemberSourceImpl } from "../../../Domain/Infra/MemberRepositoryImpl";
import { ParrainSourceImpl } from "../../../Domain/Infra/ParrainRepositoryImpl";
const EditMembre = (props) => {
  const navigate = useNavigate();
  const [formValid, setFormValid] = useState(false);
  const elector = localStorage.getItem("elector")
    ? JSON.parse(localStorage.getItem("elector"))
    : null;
  const formattedDate =
    elector && elector.DATENAISS
      ? (() => {
        const dateObj = new Date(elector.DATENAISS);
        const year = dateObj.getFullYear().toString().slice(0, 4); // Obtient les deux derniers chiffres de l'année
        const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Ajoute 1 au mois car les mois sont indexés à partir de 0
        const day = String(dateObj.getDate()).padStart(2, "0");
        return `${day}/${month}/${year}`;
      })()
      : "";

  const formateDate = (date) => {
    const dateO = new Date(date);
    const year = dateO.getFullYear().toString().slice(0, 4); 
    const month = String(dateO.getMonth() + 1).padStart(2, "0"); 
    const day = String(dateO.getDate()).padStart(2, "0");
    return `${day}/${month}/${year}`;
  }
  const [errors, setErrors] = useState({
    nom: elector?.NOMELECT ?? "",
    prenom: elector?.PRENOMELECT ?? "",
    cin: elector?.CINELECT ?? "",
    adresse: elector?.ADRESSE ?? "",
    telephone1: elector?.telephone ? elector.telephone[0] : "",
    province: '',
    district: '',
    commune: '',
    region: '',
    numero: '',
    id_fokontany: ''

  })
  const [provinces, setProvinces] = useState([]);
  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [communes, setCommunes] = useState([]);
  const [fokotany, setFokotany] = useState([]);
  const photo_residence = useRef(null);
  const photos = useRef(null);
  const photo_cin = useRef(null);
  const [fields, setFields] = useState([{ id: 1, value: '' }]);
  const [candidats, setCandidats] = useState([{ id: 1, value: '' }]);
  const getParrain = new GetParrain(new ParrainSourceImpl());
  const getMemberByID = new GetMemberByID(new MemberSourceImpl());
  const [isAnyFieldNotNull, setIsAnyFieldNotNull] = useState(false);
  const [selectedCandidat, setSelectedCandidat] = useState('');
  const [cameraStream, setCameraStream] = useState(null);
  const webcamRef = useRef(null);
  const [cameraActive, setCameraActive] = useState(false);
  const [tempCameraActive, setTempCameraActive] = useState('');
  const { id } = useParams();
  const getDefaultFieldValue = (fieldName, defaultValue) => elector?.[fieldName] ?? defaultValue;
  const [idMembre, setIdMembre] = useState(null);
  const [options, setOptions] = useState(false);
  const [optionsCelibataire, setOptionsCelibataire] = useState(false);
  const [types, setTypes] = useState([
    {
      label: "Tanoro Gasy Vonona",
      value: "TGV"
    },
    {
      label: "Madagasikara Tsy Maintsy Mandroso",
      value: "MTM"
    }
  ])
  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    lieunaiss: '',
    sexe: '',
    cin: '',
    adresse: '',
    datenaiss: '',
    celibataire: '',
    date_cin: '',
    nomPere: '',
    nomMere: '',
    province: '',
    region: '',
    commune: '',
    id_fokontany: '',
    arrondissement: '',
    profile_fb: '',
    mail: '',
    profession: '',
    nom_societe: '',
    autre_status: '',
    etude: '',
    diplome: '',
    talent: '',
    ancien_partie_politique: '',
    role_dans_la_parti: '',
    num_electeur: '',
    is_candidat_president: 0,
    num_candidat_pres: '',
    parti_presidence: '',
    voix_presidence: '',
    is_candidat_depute: 0,
    num_candidat_depute: '',
    parti_depute: '',
    periode_mandat: '',
    is_candidat_maire: 0,
    num_candidat_maire: '',
    periode_mandat_maire: '',
    parti_maire: '',
    voix_maire: '',
    motif: '',
    cause_adhesion: '',
    contribution: '',
    photo_cin: '',
    photos: '',
    photo_residence: '',
    date_adhesion: '',
    numero: '',
    district: '',
    commune: '',
    region: '',
    province: '',
    nom_parrain: '',
    prenom_parrain: '',
    role_parrain: '',
    telephone1: '',
    telephone2: '',
    telephone3: '',
    telephone: '',
    is_candidat: false,
    id_parrain: '',
    status: '',
    code_province: '',
    code_region: '',
    code_district: '',
    code_commune: '',
    is_in_list: '',
    id_electeur: '',
    type: '',
    role_partie: '',
    lieu_naiss: '',
    periode_mandat_depute: '',
    voix_depute: '',
    attente: '',
    apport: '',
    periode_mandat_presidence: '',
  });

  const [candidatOptions, setCandidatOptions] = useState([
    {
      label: 'Député',
      value: 'depute'
    },
    {
      label: 'Maire',
      value: 'maire'
    },
    {
      label: 'Presidence',
      value: 'president'
    },
  ])
  const addField = () => {
    const newField = { id: fields.length + 1, value: '' };
    setFields([...fields, newField]);
  };

  const addFieldCandidat = () => {
    const newFied = { id: fields.length + 1, value: '' };
    setCandidats([...candidats, newFied]);
  }

  const [previewFile, setPreviewFile] = useState({
    photo_cin: null,
    photos: null,
    photo_residence: null,
  })

  const handleChangeFile = async (text, param) => {

    const selectedFile = text.target.files[0];
    const formData = new FormData();
    if (selectedFile.name) {
      formData.append("file", selectedFile);
      const uploadedFile = await profileService.uploadFile(formData);

      setFormData((prevState) => ({
        ...prevState,
        [param]: uploadedFile.url,
      }));
    }

    setPreviewFile((prevState) => ({
      ...prevState,
      [param]: URL.createObjectURL(selectedFile),
    }));
  };
  const clearStore = () => {
    localStorage.setItem('elector', JSON.stringify({}))
  }
  const updateMembre = (event) => {
    event.preventDefault()
    const form = event.currentTarget;
    const isValid = validerDonnees(formData);
    
    if (!isValid) {
      Notifications.error('Veuillez vérifier les données enregistrées')
    } 
    else if(!form.checkValidity()) {
      Notifications.error('Veuillez remplir les champs obligatoire')
    }
    else  {
      event.preventDefault();
      event.stopPropagation();
      //formData.sexe = elector?.sexe == "Homme" ? "M" : "F";
      //formData.celibataire = formData.celibataire && (formData.celibataire.celibataire ? 0 : 1);

      const allowedProperties = [
        "nom", "prenom", "datenaiss", "sexe", "celibataire", "cin", "date_cin", "nomPere", "nomMere", "adresse", "id_fokontany", "profile_fb",
        "mail", "profession", "nom_societe", "autre_status", "etude", "diplome", "talent", "is_in_list", "num_electeur", "is_candidat_president",
        "num_candidat_pres", "parti_presidence", "voix_presidence", "is_candidat_depute", "num_candidat_depute", "parti_depute", "is_candidat_maire",
        "num_candidat_maire", "parti_maire", "voix_maire", "motif", "contribution", "photo_cin", "photos", "photo_residence", "date_adhesion", "nom_parrain",
        "prenom_parrain", "role_parrain", "telephone", "status", "id_parrain", "photo_cin", "photos", "photo_residence", "telephone1", "telephone2",
        "telephone3", "depute", "is_candidat_presidence", "is_candidat_maire", "is_candidat_depute", "id_electeur", "voix_depute", "periode_mandat_maire",
        "periode_mandat_depute", "type", "num_adhesion", "role_partie", "lieu_naiss", "ancien_partie_politique", "cause_adhesion", "attente", "apport", "periode_mandat_presidence"
      ];


      const filteredData = Object.keys(formData).reduce((acc, key) => {
        if (
          allowedProperties.includes(key) &&
          formData[key] !== null &&
          formData[key] !== undefined
        ) {
          if (key === "telephone") {
            // Traitez le cas spécial de la propriété 'telephone'
            acc[key] = formData[key];
          } else {
            acc[key] = formData[key];
          }
        }
        return acc;
      }, {});

      filteredData['telephone'] = [formData.telephone1, formData.telephone2, formData.telephone3]
      ListeService.updateMembre(filteredData, id)
        .then((responses) => {
          Notifications.success("Membre a été modifier avec succès");
          setTimeout(() => {
            navigate('/member/list')
          }, 2000)


        })
        .catch((error) => {
          Notifications.error(error.response.data.errorMessage);
        });
    } 



  }
  const getRegions = (idProvince) => {
    ListeService.getRegions(idProvince).then(responses => {
      setRegions(responses.data.data)
    });

  }
  const getDistricts = (idRegion) => {
    ListeService.getDistricts(idRegion).then(responses => {
      setDistricts(responses.data.data)
    });
  }
  const getCommunes = (idDistrict) => {
    ListeService.getCommunes(idDistrict).then(responses => {
      setCommunes(responses.data.data)
    });
  }

  const getFokontany = (idCommune) => {
    ListeService.getFokontany(idCommune).then(responses => {
      setFokotany(responses.data.data)
    });
  }
  const [validated, setValidated] = useState(false);

  const openFile = async (text, param) => {
    switch (param) {
      case 'photo_residence':
        photo_residence.current.click();
        break;
      case 'photos':
        photos.current.click();
        break;
      case 'photo_cin':
        photo_cin.current.click()
        break;
      default:
        break;
    }
  }

  const handleChange = (text, param, pattern) => {
    const updateFormData = (key, value) => {
      setFormData((prevState) => ({
        ...prevState,
        [key]: value !== undefined ? value : '',
      }));
    };

    const updateErrors = (errorText) => {
      setErrors((prevState) => ({
        ...prevState,
        [param]: errorText,
      }));
    };

    const handleSpecialCase = () => {
      if (param === 'id_parrain') {
        getParrain
          .fetchParrain(text.target.value)
          .then((item) => {
            const data = item.data && item.data[0];
            const nouvelEtat = {
              ...formData,
              id_parrain: text.target.value,
              prenom_parrain: data ? data.prenom : '',
              nom_parrain: data ? data.nom : '',
              role_parrain: data ? data.role_parrain : '',
            };
            setFormData(nouvelEtat);
          })
          .catch((error) => {
            console.error('Erreur lors de la récupération des données du parrain :', error);
          });
      }
    };

    const isFieldEmpty = (fieldValue) => fieldValue === '';

    switch (param) {
      case 'province':
        getRegions(text.target.value);
        break;
      case 'region':
        getDistricts(text.target.value);
        break;
      case 'district':
        getCommunes(text.target.value);
        break;
      case 'commune':
        getFokontany(text.target.value);
        break;
      default:
        break;
    }

    // Check for empty fields and update errors
    Object.keys(formData).forEach((key) => {
      if (isFieldEmpty(formData[key])) {
        updateErrors('Champ obligatoire');
      }
    });

    // Check pattern and update errors
    if (pattern !== undefined && text.target.value.match(pattern) === null) {
      updateErrors('Champ invalide');
    }

    // Update form data
    updateFormData(param, text.target.value);

    // Handle special cases
    handleSpecialCase();

    setIsAnyFieldNotNull(true);

    return false;
  };

  const onCapture = (imageData) => {
    setCameraActive(false)
    return false;
  }
  const startCamera = (event, param) => {
    /*setCameraActive(true)
    setTempCameraActive(param)*/
  }
  const capture = useCallback((e) => {

    e.preventDefault()
    const imageSrc = webcamRef.current.getScreenshot();
    setCameraActive(true);
    return false;

  }, [cameraActive, onCapture]);

  const stopCamera = () => {
    if (webcamRef.current) {
      webcamRef.current.video.pause();
      const tracks = webcamRef.current.video.srcObject.getTracks();
      tracks.forEach(track => track.stop());

      const imageSrc = webcamRef.current.getScreenshot();
      const binaryString = atob(imageSrc.split(',')[1]);

      setPreviewFile(prevPreview => ({
        ...prevPreview,
        [tempCameraActive]: imageSrc,
      }));

      setFormData(prevFormData => ({
        ...prevFormData,
        [tempCameraActive]: binaryString,
      }));

      setCameraActive(false);
    }
  };

 
  const validerDonnees =(formData)=> {
    const {
      nomPere,nomMere, nom, prenom, num_adhesion, cin, date_cin, datenaiss, telephone1, mail
    } = formData;
  
    const patterns = {
      nomPere: /^[a-zA-Z\s]*$/,
      nomMere: /^[a-zA-Z\s]*$/,
      nom: /^[a-zA-Z\s]*$/,
      prenom: /^[a-zA-Z\s]*$/,
      num_adhesion: /^[0-9]+$/i,
      cin: /^(?:[1-6]\d{2}|7[0-9][0-9])\d{9}$/,
      date_cin: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
      datenaiss: [
        /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(1930|19[3-9][0-9]|200[0-5])$/,
        /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/
      ],
      telephone1: /^(?:\+261|0)?3[2348]\s?\d{2}\s?\d{3}\s?\d{2}$/,
      mail: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    };
  
    const validationResult = {};
  
    for (const field in patterns) {
      if (formData[field]) {
        if (!Array.isArray(patterns[field])) {
          const regex = new RegExp(patterns[field]);
          const isValid = regex.test(formData[field]);
          validationResult[field] = isValid;
        } else {
          let fieldIsValid = true;
          patterns[field].forEach((pattern, index) => {
            const regex = new RegExp(pattern);
            const isValid = regex.test(formData[field]);
            
            if (!isValid) {
              fieldIsValid = false;
            }
          });
          validationResult[field] = fieldIsValid;
        }
        
      }
    }
  
  
    for (const field in validationResult) {
      if (!validationResult[field]) {
        return false; 
      }
    }
  
    return true; 
  }

  const validateInput = ($input, regex, $errorElement, errorMessage) => {
    const value = $input.val();
    const valid = regex.test(value);
    if (value === '') {
      $errorElement.text('');
      setFormValid(false)
    } else if (!valid) {
      setFormValid(true)
      $errorElement.text(errorMessage);
    } else {
      $errorElement.text('');
      setFormValid(false)
    }
    $input.toggleClass('error', !valid);
  }

  const  getID = (nombre, first)=> {
    const nombreStr = nombre.toString();
    if (nombreStr.length >= first) {
      const nb = nombreStr.slice(0, first);
      return parseInt(nb); 
    } else {
      return nombre;
    }
  }
  


  useEffect(() => {
    if (id) {
      getMemberByID.memberByID(id).then(response => {
        const data = response.data;
        console.log('data',data)
       // setIdMembre(data.id_membre)
        previewFile.photos = data.photos;
        previewFile.photo_residence = data.photo_residence;
        previewFile.photo_cin = data.photo_cin;
        if (data.id_fokontany) {
          getDistricts(getID(data.id_fokontany, 2));
          getRegions(getID(data?.id_fokontany, 1));
          getCommunes(getID(data?.id_fokontany, 4));
          getFokontany(getID(data?.id_fokontany, 6));
        }
        setOptionsCelibataire(data?.celibataire)
        const newData = {
          nom: data?.nom,
          prenom: data?.prenom,
          lieu_naiss: data?.lieu_naiss,
          sexe: data?.sexe,
          cin: data?.cin,
          adresse: data?.adresse,
          datenaiss:  formateDate(data?.datenaiss),
          celibataire: data?.celibataire,
          date_cin: formateDate(data?.date_cin),
          nomPere: data?.nomPere,
          nomMere: data?.nomMere,
          province: data?.province,
          region: data?.region,
          commune: data?.commune,
          id_fokontany: data?.id_fokontany,
          arrondissement: data?.arrondissement,
          profile_fb: data?.profile_fb,
          mail: data?.mail,
          profession: data?.profession,
          nom_societe: data?.nom_societe,
          autre_status: data?.autre_status,
          etude: data.etude,
          diplome: data.diplome,
          talent: data.talent,
          ancien_partie_politique: data?.ancien_partie_politique,
          role_dans_la_parti: data?.role_dans_la_parti,
          num_electeur: data?.num_electeur,
          is_candidat_president: data?.is_candidat_president,
          num_candidat_pres: data?.num_candidat_pres,
          parti_presidence: data?.parti_presidence,
          voix_presidence: data?.voix_presidence,
          is_candidat_depute: data?.is_candidat_depute,
          num_candidat_depute: data?.num_candidat_depute,
          parti_depute: data?.parti_depute,
          periode_mandat: data?.periode_mandat,
          is_candidat_maire: data?.is_candidat_maire,
          num_candidat_maire: data?.num_candidat_maire,
          periode_mandat_maire: data?.periode_mandat_maire,
          parti_maire: data?.parti_maire,
          voix_maire: data?.voix_maire,
          motif: data?.motif,
          cause_adhesion: data?.cause_adhesion,
          contribution: data?.contribution,
          photo_cin: data?.photo_cin,
          photos: data?.photos,
          photo_residence: data?.photo_residence,
          date_adhesion: formateDate(data?.date_adhesion),
          district: data?.district,
          commune: data?.commune,
          region: data?.region,
          province: data?.province,
          nom_parrain: data?.nom_parrain,
          prenom_parrain: data?.prenom_parrain,
          role_parrain: data?.role_parrain,
          telephone1: data?.telephone[0],
          telephone2: data?.telephone[1],
          telephone3: data?.telephone[2],
          telephone: data?.telephone,
          is_candidat: data?.is_candidat_depute || data?.is_candidat_maire || data?.is_candidat_president ? true: false,
          id_parrain: data?.id_parrain,
          status: data?.status,
          options: data?.is_candidat_depute || data?.is_candidat_maire || data?.is_candidat_president ? true: false,
          is_in_list: true,
          id_electeur: data?.id_electeur,
          type: data?.type,
          role_partie: data?.role_partie,
          lieu_naiss: data?.lieu_naiss,
          periode_mandat_depute: data?.periode_mandat_depute,
          voix_depute: data?.voix_depute,
          attente: data?.attente,
          apport: data?.apport,
          periode_mandat_presidence: data?.periode_mandat_presidence,
          num_adhesion: data?.num_adhesion
        }
        console.log(newData)
        setFormData(newData);
        console.log(newData)
      }).catch(error => {
        console.log(error)
      })
    }
    


    $('.nom').on('keyup', function (event) {
      event.preventDefault();
      validateInput($(this), /^[a-zA-Z\s]*$/, $('#nom'), 'Nom non valide');
    });

    $(".num_adhesion").on("keyup", function (event) {
      event.preventDefault();
      validateInput(
        $(this),
        /^[0-9]+$/i,
        $("#num_adhesion"),
        "Numéro d\'adhésion n\'est pas valide"
      );
    });
    $('.prenom').on('keyup', function (event) {
      event.preventDefault();
      validateInput($(this), /^[a-zA-Z\s]*$/, $('#prenom'), 'Prénom non valide');
    });

    $('.cin').on('keyup', function (event) {
      event.preventDefault();
      validateInput($(this), /^(?:[1-6]\d{2}|7[0-9][0-9])\d{9}$/, $('#cin'), 'Cin non valide');
    });

    $('.date_cin').on('keyup', function (event) {
      event.preventDefault();
      validateInput($(this), /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/, $('#date_cin'), 'Date non valide');
      if (event.target.value === '') {
        $(this).removeClass('error')
      }
    });

   

    $('.datenaiss').on('keyup', function (event) {
      event.preventDefault();
      const $datenaiss = $('.datenaiss');
      const $datenaissMessage = $('#datenaiss');
      const dateNaissanceRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
      const dateNaissancePlageRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(1930|19[3-9][0-9]|200[0-5])$/;
      const inputValue = $(this).val();
      let errorMessage = '';

      if (!dateNaissanceRegex.test(inputValue)) {
        errorMessage = 'Date de naissance non valide.';
      } else if (!dateNaissancePlageRegex.test(inputValue)) {
        const birthDate = new Date(inputValue.split('/').reverse().join('/'));
        const minDateMajeur = new Date('2005-01-01');
        const maxDateMineur = new Date('1930-01-01');

        if (birthDate < maxDateMineur) {
          errorMessage = 'Âge hors de la plage acceptée';
        } else if (birthDate > minDateMajeur) {
          errorMessage = 'Le membre doit être majeur.';
        }
      }
      $datenaissMessage.text(errorMessage);
      $(this).toggleClass('error', errorMessage !== '');

    });
    
    $('.nomPere').on('keyup', function (event) {
      event.preventDefault();
      validateInput($(this), /^[a-zA-Z\s]*$/, $('#nomPere'), 'Nom du père non valide');
    });

    $('.nomMere').on('keyup', function (event) {
      event.preventDefault();
      validateInput($(this), /^[a-zA-Z\s]*$/, $('#nomMere'), 'Nom de la mère non valide');
    });

    $('.adresse').on('keyup', function (event) {
      event.preventDefault();
      validateInput($(this), /.+/, $('#adresse'), 'Adresse obligatoire');
    });

    $('.id_fokontany').on('keyup', function (event) {
      event.preventDefault();
      validateInput($(this), /.+/, $('#id_fokontany'), 'Fokotany obligatoire');
    });

    $('.telephone1').on('keyup', function (event) {
      event.preventDefault();
      validateInput($(this), /^(?:\+261|0)?3[2348]\s?\d{2}\s?\d{3}\s?\d{2}$/, $('#telephone1'), 'Numéro de téléphone non valide');
    });

    $('.profile_fb').on('keyup', function (event) {
      event.preventDefault();
      validateInput($(this), /.+/, $('#profile_fb'), 'Profil Facebook obligatoire');
    });

    $('.mail').on('keyup', function (event) {
      event.preventDefault();
      validateInput($(this), /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, $('#mail'), 'Adresse email non valide');
    });

    $('.profession').on('keyup', function (event) {
      event.preventDefault();
      validateInput($(this), /.+/, $('#profession'), 'Profession obligatoire');
    });

    $('.nom_societe').on('keyup', function (event) {
      event.preventDefault();
      validateInput($(this), /.+/, $('#nom_societe'), 'Nom de société obligatoire');
    });

    $('.talent').on('keyup', function (event) {
      event.preventDefault();
      validateInput($(this), /.+/, $('#talent'), 'Talent obligatoire');
    });


    ListeService.getProvinces().then(responses => {
      setProvinces(responses.data.data)
    })

  }, []);

  const handleClose = () => setCameraActive(false);

  return (

    <div>

      <CameraModal
        cameraActive={cameraActive}
        webcamRef={webcamRef}
        handleClose={handleClose}
        stopCamera={stopCamera}
      />

      <Form className="needs-validation create-member" validated={validated} noValidate onSubmit={updateMembre}>

        <div className="row" id="status">
          <div className="col-md-3">
            <select className="form-control" onClick={(text, param) => handleChange(text, 'status')}>
              <option value={'Membre'}>Membre</option>
              <option value={'Partisant'}>Partisant</option>
              <option value={'Symphatisant'}>Symphatisant</option>
            </select>
          </div>
        </div>

        {partisans.map((items, key) => {
          return (
            <div className="card" key={key}>
              <div className="card-body">
                <h4 className="card-title" dangerouslySetInnerHTML={{ __html: items.section.title }} />

                <div className="row">
                  {
                    items.section.form?.map((forms, keyForm) => {

                      return (
                        !forms.candidat ? (
                          <div className={'col-lg-' + forms.colSize}>
                            <div className="row">
                              {
                                forms.candidat ? (
                                  <div className="form-add candidat" onClick={addField}>Ajouter des champs +</div>
                                ) : null
                              }

                              {
                                forms.input?.map((inputItem, keyInput) => {
                                  if (inputItem.type !== 'radio') {
                                    return (
                                      <Form.Group key={keyInput} className={'mb-3 col-lg-' + inputItem.colSize + (inputItem.formAdd ? ' form-add' : '')} >

                                        {
                                          inputItem.type !== 'formadd' ? (
                                            <Form.Label className="label-login ">{inputItem.label}</Form.Label>
                                          ) : <div className="form-add " onClick={addField}>{inputItem.label + ' +'}</div>
                                        }

                                        {inputItem.type === 'text' || inputItem.type === 'date' ? (
                                          <Form.Control

                                            className={formData[inputItem.name] === '' && inputItem.required ? inputItem.id + ' error' : inputItem.id}
                                            required={inputItem.required}
                                            onChange={(text) => handleChange(text, inputItem.name, inputItem.pattern,)} value={formData[inputItem.name]} type={inputItem.type} placeholder={inputItem.placeholder} />
                                        ) : inputItem.type === 'textarea' ? (
                                          <Form.Control value={formData[inputItem.name]} onChange={(text, param) => handleChange(text, inputItem.name)} className={inputItem.class + "form-control"} as="textarea" rows={keyInput + 1} placeholder={inputItem.placeholder} />

                                        )
                                          : inputItem.type === 'file' ? (
                                            <div>
                                              <Form.Control  ref={inputItem.name === 'photo_residence' ? photo_residence :
                                                inputItem.name === 'photos' ? photos : photo_cin} 
                                                onChange={(text, param) => handleChangeFile(text, inputItem.name)} 
                                                className={inputItem.required ? inputItem.id + ' error '+inputItem.class + "form-control" : inputItem.class + "form-control"} 
                                                type={inputItem.type} placeholder={inputItem.placeholder}
                                               
                                                 />
                                              <div className={" custom-input-file " + formData[inputItem.name] === '' && isAnyFieldNotNull ? 'error' : ''}>
                                                <label>
                                                </label>
                                                <Button className="file-btn" onClick={(text, param) => openFile(text, inputItem.name)}>Importer une Photo</Button>

                                              </div>
                                              {
                                                inputItem.name === 'photo_residence' && previewFile.photo_residence ? (
                                                  <img className="preview" src={previewFile.photo_residence} />
                                                ) :
                                                  inputItem.name === 'photos' && previewFile.photos ? (
                                                    <img className="preview" src={previewFile.photos} />
                                                  ) : inputItem.name === 'photo_cin' && previewFile.photo_cin ?
                                                    (
                                                      <img className="preview" src={previewFile.photo_cin} />
                                                    )
                                                    : null
                                              }

                                            </div>
                                          ) : inputItem.type === 'formadd' ?
                                            (
                                              <div>
                                                {fields.map((field) => (
                                                  <div className="col-md-12 form-left" key={field.id}>
                                                    <input
                                                      className="form-control mb-3"
                                                      placeholder={'Etude' + field.id}
                                                      type="text"
                                                      name="etude"
                                                      value={formData[inputItem.name]}
                                                      onChange={(e) => handleChange(e, "etude" + field.id)}
                                                    />
                                                  </div>
                                                ))}
                                              </div>
                                            )
                                            : (
                                              <Form.Select className={formData[inputItem.name] === '' && inputItem.required ? 'error' : ''} required={inputItem.required} onClick={(text, param) => handleChange(text, inputItem.name)} onChange={(text, param) => handleChange(text, inputItem.name)} aria-label="Default select" placeholder={inputItem.placeholder}>
                                                {(() => {
                                                  switch (inputItem.name) {
                                                    case 'province':
                                                      return provinces?.map((item, index) => {
                                                        <option value={""}></option>
                                                        return (
                                                          <option selected={item.code_p === formData['code_province'] ? 'selected' : ''} key={index} value={item.code_p}>{item.libelle_province}</option>
                                                        )
                                                      })
                                                    case 'region':
                                                      return regions?.map((item, index) => {
                                                        <option value={""}></option>
                                                        return (
                                                          <option selected={item.co === formData['code_region'] ? 'selected' : ''} key={index} value={item.co}>{item.libelle_region}</option>
                                                        )
                                                      })
                                                    case 'district':
                                                      return districts?.map((item, index) => {
                                                        <option value={""}></option>
                                                        return (
                                                          <option selected={item.libelle_district === formData.district ? 'selected' : ''} key={index} value={item.code}>{item.libelle_district}</option>
                                                        )
                                                      })
                                                    case 'commune':
                                                      return communes?.map((item, index) => {
                                                        <option value={""}></option>
                                                        return (
                                                          <option selected={item.code_c === formData['code_commune'] ? 'selected' : ''} key={index} value={item.code_c}>{item.libelle_commune}</option>
                                                        )
                                                      })
                                                    case 'id_fokontany':
                                                      return fokotany?.map((item, index) => {
                                                        <option value={""}></option>
                                                        return (
                                                          <option selected={item.code_fok === formData['id_fokontany'] ? 'selected' : ''} key={index} value={item.code_fok}>{item.libelle_fokontany}</option>
                                                        )
                                                      })
                                                    case 'type':
                                                      return types?.map((item, index) => {
                                                        <option value={""}></option>
                                                        return (
                                                          <option selected={item.value === formData[inputItem.name] ? "selcted" : ""} key={index} value={item.value}>{item.label}</option>
                                                        )
                                                      })

                                                    default:
                                                      break;
                                                  }
                                                })()}

                                              </Form.Select>
                                            )}


                                        <div id={' invalid-feedback d-block'}>
                                          <span className="errors" id={inputItem.id}></span>
                                        </div>


                                      </Form.Group>
                                    )
                                  } else {
                                    return (
                                      <Form.Group key={keyInput} className={'mb-3 radio-custom col-xx col-md-6 col-lg-' + inputItem.colSize} controlId={inputItem.name + '_' + inputItem.placeholder}>
                                        <Form.Label className="label-login ">{inputItem.label}</Form.Label>
                                        <div className={"form-radio " + inputItem.class}>
                                          {
                                            inputItem.name === 'is_in_list' ? (
                                              <Form.Check.Input
                                                onChange={(event, param) => handleChange(event, inputItem.name)}
                                                checked={inputItem.value === formData[inputItem.name] }
                                                value={formData[inputItem.name]} className="form-check-input"
                                                type={inputItem.type} isValid
                                                name={inputItem.name}
                                              />
                                            ) : inputItem.name === 'is_candidat' ?
                                            (
                                              <>
                                                <Form.Check.Input
                                                  onChange={(event, param) => handleChange(event, inputItem.name)}
                                                  onClick={
                                                    (event) => {
                                                      event.target.value !== formData.is_candidat ? setOptions(false) : setOptions(true);
                                                      setOptions(!options);
                                                    }}
                                                  checked={inputItem.value === !options}
                                                  value={formData[inputItem.name]} className="form-check-input"
                                                  type={inputItem.type} isValid
                                                  name={inputItem.name}
                                                />
                                              </>
                                            ):
                                            inputItem.name === 'celibataire' ?
                                            (
                                              
                                              <Form.Check.Input
                                                onChange={(event, param) => handleChange(event, inputItem.name)}
                                                onClick={
                                                  (event) => {
                                                    event.target.value !== formData.is_candidat ? setOptions(false) : setOptions(true);
                                                    setOptionsCelibataire(!optionsCelibataire);
                                                  }}
                                                checked={inputItem.value == optionsCelibataire }
                                                value={inputItem.value} className="form-check-input"
                                                type={inputItem.type} isValid
                                                name={inputItem.name}
                                              />
                                              
                                            )
                                            :
                                              (
                                                <Form.Check.Input
                                                  onChange={(event, param) => handleChange(event, inputItem.name)}
                                                  checked={inputItem.value === formData[inputItem.name]}
                                                  value={inputItem.value} className="form-check-input"
                                                  type={inputItem.type} isValid
                                                  name={inputItem.name}
                                                />
                                              )
                                          }
                                          <Form.Check.Label>{inputItem.placeholder}</Form.Check.Label>
                                        </div>
                                      </Form.Group>
                                    )
                                  }
                                })
                              }

                            </div>
                          </div>
                        ) : (
                          <div className={'col-lg-' + forms.colSize}>
                            <div className="row">

                              {
                                !options ?
                                  (
                                    <>

                                      <div className="mb-3 col-lg-2">
                                        <Form.Label className="label-login ">Candidat Maire:</Form.Label>
                                        <select aria-label="Default select" placeholder="Candidat" className="form-control  form-select" onChange={(event) => {
                                          setSelectedCandidat(event.target.value);
                                          handleChange(event, 'is_candidat_maire');
                                        }}>

                                          <option>Select</option>
                                          <option selected={formData.is_candidat_maire ? true : false} value="1">Oui</option>
                                          <option selected={formData.is_candidat_maire === false ? true : false} value="0">Non</option>
                                        </select>
                                      </div>
                                      <div className="mb-3 col-lg-2">
                                        <Form.Label className="label-login ">N° candidat</Form.Label>
                                        <Form.Control value={formData.num_candidat_maire} onChange={(text, param) => handleChange(text, 'num_candidat_maire')} className="form-control" type="text" />

                                      </div>
                                      <div className="mb-3 col-lg-3">
                                        <Form.Label className="label-login ">Période du Mandat</Form.Label>
                                        <Form.Control value={formData.periode_mandat_maire} onChange={(text, param) => handleChange(text, 'periode_mandat_maire')} className="form-control" type="text" />

                                      </div>
                                      <div className="mb-3 col-lg-3">
                                        <Form.Label className="label-login ">Parti ou association</Form.Label>
                                        <Form.Control value={formData.parti_maire} onChange={(text, param) => handleChange(text, 'parti_maire')} className="form-control" type="text" />

                                      </div>
                                      <div className="mb-3 col-lg-2">
                                        <Form.Label className="label-login ">Nombre de voix reçus en % </Form.Label>
                                        <Form.Control  value={formData.voix_maire} onChange={(text, param) => handleChange(text, 'voix_maire')} className="form-control" type="text" />
                                      </div>


                                      <>
                                        <div className="mb-3 col-lg-2">
                                          <Form.Label className="label-login ">Candidat député</Form.Label>
                                          <select aria-label="Default select" placeholder="Candidat" className="form-control  form-select" onChange={(event) => {
                                            setSelectedCandidat(event.target.value);
                                            handleChange(event, 'is_candidat_depute');
                                          }}>


                                            <option>Select</option>
                                            <option selected={formData.is_candidat_depute ? true : false} value="1">Oui</option>
                                            <option selected={formData.is_candidat_depute === false ? true : false} value="0">Non</option>
                                          </select>
                                        </div>
                                        <div className="mb-3 col-lg-2">
                                          <Form.Label className="label-login ">N° candidat</Form.Label>
                                          <Form.Control value={formData.num_candidat_depute} onChange={(text, param) => handleChange(text, 'num_candidat_depute')} className="form-control" type="text" />

                                        </div>
                                        <div className="mb-3 col-lg-3">
                                          <Form.Label className="label-login ">Période du Mandat</Form.Label>
                                          <Form.Control value={formData.periode_mandat_depute} onChange={(text, param) => handleChange(text, 'periode_mandat_depute')} className="form-control" type="text" />

                                        </div>
                                        <div className="mb-3 col-lg-3">
                                          <Form.Label className="label-login ">Parti ou association</Form.Label>
                                          <Form.Control value={formData.parti_depute} onChange={(text, param) => handleChange(text, 'parti_depute')} className="form-control" type="text" />

                                        </div>
                                        <div className="mb-3 col-lg-2">
                                          <Form.Label className="label-login ">Nombre de voix reçus en % </Form.Label>
                                          <Form.Control value={formData.voix_depute}  onChange={(text, param) => handleChange(text, 'voix_depute')} className="form-control" type="text" />
                                        </div>
                                      </>

                                      <>
                                        <div className="mb-3 col-lg-2">
                                          <Form.Label className="label-login ">Candidat président: </Form.Label>
                                          <select aria-label="Default select" placeholder="Candidat" className="form-control  form-select" onChange={(event) => {
                                            setSelectedCandidat(event.target.value);
                                            handleChange(event, 'is_candidat_president');
                                          }}>

                                            <option>Select</option>
                                            <option selected={formData.is_candidat_president ? true : false} value="1">Oui</option>
                                            <option selected={formData.is_candidat_president === false ? true : false} value="0">Non</option>
                                          </select>
                                        </div>
                                        <div className="mb-3 col-lg-2">
                                          <Form.Label className="label-login ">N° candidat</Form.Label>
                                          <Form.Control value={formData.num_candidat_pres}  onChange={(text, param) => handleChange(text, 'num_candidat_pres')} className="form-control" type="text" />

                                        </div>
                                        <div className="mb-3 col-lg-3">
                                          <Form.Label className="label-login ">Période du Mandat</Form.Label>
                                          <Form.Control value={formData.periode_mandat_presidence} onChange={(text, param) => handleChange(text, 'periode_mandat_presidence')} className="form-control" type="text" />

                                        </div>
                                        <div className="mb-3 col-lg-3">
                                          <Form.Label className="label-login ">Parti ou association</Form.Label>
                                          <Form.Control value={formData.parti_presidence} onChange={(text, param) => handleChange(text, 'parti_presidence')} className="form-control" type="text" />

                                        </div>
                                        <div className="mb-3 col-lg-2">
                                          <Form.Label className="label-login ">Nombre de voix reçus en % </Form.Label>
                                          <Form.Control value={formData.voix_presidence} onChange={(text, param) => handleChange(text, 'voix_presidence')} className="form-control" type="text" />
                                        </div>
                                      </>
                                    </>

                                  ) : null
                              }


                            </div>
                          </div>
                        )
                      )
                    })
                  }
                </div>
              </div>
            </div >
          )
        })
        }
        <div className="d-flex justify-content-center">
          <div className="col-md-4 d-flex">
            <Link to='/add/member'>
              <Button className="btn btn-cancel" onClick={clearStore}>Annuler</Button>
            </Link>
            <Button type="submit"
              variant="primary"
              className="btn-login form-membre"
            >
              Enregistrer <img src={Check} alt="Enregistrer" />
            </Button>
          </div>
        </div>
      </Form >
    </div >
  )
}

export default EditMembre;