import axios from "axios";
import Notifications from "../Presentation/Pages/Notifications/Notifications";
// import { setTimeout } from "timers/promises";
import { BASE_URL } from "../Constant/Url";

export const loginWs = (credentials) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(BASE_URL + "/auth/signin", credentials)
      .then((response) => {
        localStorage.setItem("user", JSON.stringify(response.data));
        Notifications.success("Connexion réussie");
        // dispatch({ type: "LOGIN_SUCCESS", payload: response.data });

        setTimeout(() => {
          const role = response.data.body.role
          switch (role) {
            case 'impression' || 'impression_excel' || 'admin_impression':
              window.location.href = "/member/list";
            break;
              
            case 'saisie':
              window.location.href = "/add/member";
            break;
              
            case 'respo_dashboard_district':
              window.location.href = "/dashboarddistrict";
            break
              
            default:
              window.location.href = "/dashboard";
            break;
          }
         
        }, 1000);
      })
      .catch((error) => {
        Notifications.error(error.response.data.errorMessage);
      });
  });
};