import StorageService from "../Services/storage.service";

const getUserRole = () => {
    const userData = StorageService.getItem("user");
    if (userData) {
        const user = userData;
        const role = user.body.role;
        return role.toLowerCase();
    } else {
        return null;
    }
}

export default getUserRole;